import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import { GET_NAVBAR_MENUS } from 'mocks/queries';
import LoginSidebar from 'components/consumer/LoginSidebar/LoginSidebar';
import scrollToTop from 'commons/scrollToTop';
import normalizeInternalLink from 'commons/normalizeInternalLink';
import CtaButton from 'components/essentials/Cta/CtaButton';
import IconArrowRightSimple from 'components/icons/iconArrowRightSimple';
import ArrowDownSimples from 'components/icons/iconArrowDownSimple';
import CtaLink from 'components/essentials/Cta/CtaLink';
import useUserData from 'data-hooks/useUserData';
import useSuspenseQuery from 'commons/useSuspenseQuery';
import IconUserAccount from '../../icons/iconUserAccount';
import ProgressLoader from '../../essentials/ProgressLoader/ProgressLoader';

const LOCATION = {
  category: [
    {
      categoryTitle: 'Locations',
      subcategory: [
        {
          subcategoryTitle: 'Virtual Showroom',
          link: '/showrooms/virtual/',
        },
        {
          subcategoryTitle: 'Austin, TX',
          link: '/showrooms/austin/',
        },
        {
          subcategoryTitle: 'Brooklyn, NY',
          link: '/showrooms/brooklyn/',
        },
        {
          subcategoryTitle: 'Chicago, IL',
          link: '/showrooms/chicago/',
        },
        {
          subcategoryTitle: 'Denver, CO',
          link: '/showrooms/denver/',
        },
        {
          subcategoryTitle: 'Los Angeles, CA',
          link: '/showrooms/dtla/',
        },
        {
          subcategoryTitle: 'Manhattan, NY',
          link: '/showrooms/manhattan/',
        },
        {
          subcategoryTitle: 'Philadelphia, PA',
          link: '/showrooms/philadelphia/',
        },
        {
          subcategoryTitle: 'Portland, OR',
          link: '/showrooms/portland/',
        },
        {
          subcategoryTitle: 'San Francisco, CA',
          link: '/showrooms/san-francisco/',
        },
        {
          subcategoryTitle: 'Seattle, WA',
          link: '/showrooms/seattle/',
        },
        {
          subcategoryTitle: 'Washington DC',
          link: '/showrooms/dc/',
        },
        {
          subcategoryTitle: 'West Hollywood, CA',
          link: '/showrooms/los-angeles/',
        },
        {
          subcategoryTitle: 'All Showrooms',
          link: '/showrooms/',
        },
      ],
    },
  ],
};

const ConsumerMobileFooterMenu = () => {
  const { data } = useSuspenseQuery(GET_NAVBAR_MENUS);
  const [menuVisible, setMenuVisible] = useState({});
  const [signInModalVisible, setSignInModalVisible] = useState(false);

  const { isLoggedIn, firstName } = useUserData();

  const toggleMenu = menuType => {
    setMenuVisible(prevState => {
      const updatedMenu = cloneDeep(prevState);
      updatedMenu[menuType] = !updatedMenu[menuType];
      return updatedMenu;
    });
  };

  const showSignInModal = () => {
    setSignInModalVisible(true);
  };

  const hideSignInModal = () => {
    setSignInModalVisible(false);
    scrollToTop();
  };

  if (!data || data.loading)
    return (
      <div className="hidden max-[767px]:w-full max-[767px]:flex-col max-[767px]:justify-center max-[767px]:items-center max-[767px]:bg-white max-[767px]:flex max-[767px]:px-0 max-[767px]:py-4">
        <ProgressLoader color="dark-light" />
      </div>
    );

  const newData =
    data?.getNavigationMenus && Array.isArray(data?.getNavigationMenus?.learn)
      ? {
          ...data,
          getNavigationMenus: {
            ...data.getNavigationMenus,
            learn: [...data.getNavigationMenus.learn, LOCATION],
          },
        }
      : data;

  return (
    <div className="hidden max-[767px]:flex max-[767px]:flex-col max-[767px]:w-full max-[767px]:m-0">
      {newData.getNavigationMenus &&
        newData.getNavigationMenus.learn?.map(({ category }, i) =>
          category.map(({ categoryTitle, subcategory }) => {
            let categoryTitleLower = categoryTitle.toLowerCase();
            categoryTitleLower = categoryTitleLower.replace(' ', '');
            return (
              <div
                className="w-full cursor-pointer border-b border-solid border-gray-light5"
                key={`${categoryTitleLower}_${i + 4}`}
              >
                <button
                  type="button"
                  className="[background:none] border-0 flex w-full flex-row justify-between px-[3.333vw] py-4"
                  onClick={() => toggleMenu(`${categoryTitleLower}`)}
                >
                  <div className="text-[.78rem] leading-[.9rem] text-gray-light1 font-bold">
                    {categoryTitle}
                  </div>
                  <div className="w-[.9rem] h-[.9rem] leading-[.9rem] -mt-0.5 flex justify-center items-center [&_img]:h-full">
                    {!menuVisible[`${categoryTitleLower}`] ? (
                      <IconArrowRightSimple />
                    ) : (
                      <ArrowDownSimples width={16} height={9} />
                    )}
                  </div>
                </button>
                <div
                  className={classNames(
                    'menu-list transition-all duration-700 w-full',
                    {
                      'flex flex-col opacity-100':
                        menuVisible[categoryTitleLower],
                      'hidden flex-col opacity-0': !menuVisible[
                        categoryTitleLower
                      ],
                    }
                  )}
                >
                  <ul>
                    {subcategory &&
                      subcategory.map(({ subcategoryTitle, link }) => {
                        if (subcategoryTitle === 'Blog') {
                          const href = 'https://joybird.com/blog/';
                          return (
                            <li key={`${subcategoryTitle}-link1`}>
                              <a key={`${subcategoryTitle}`} href={href}>
                                {subcategoryTitle}
                              </a>
                            </li>
                          );
                        }

                        if (subcategoryTitle === 'FAQ') {
                          return (
                            <li key={`${subcategoryTitle}-link2`}>
                              <a key={`${subcategoryTitle}`} href={link}>
                                {subcategoryTitle}
                              </a>
                            </li>
                          );
                        }
                        return (
                          <li key={`${subcategoryTitle}-link3`}>
                            <Link
                              to={normalizeInternalLink(link)}
                              key={`${subcategoryTitle}`}
                            >
                              {subcategoryTitle}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            );
          })
        )}
      {newData.getNavigationMenus &&
        newData.getNavigationMenus.shop?.map(({ category }, i) =>
          category.map(({ categoryTitle, subcategory }) => {
            let categoryTitleLower = categoryTitle.toLowerCase();
            categoryTitleLower = categoryTitleLower.replace(' & ', '');
            return (
              <div
                className="w-full cursor-pointer border-b border-solid border-gray-light5"
                key={`${categoryTitleLower}_${i + 4}`}
              >
                <button
                  type="button"
                  className="[background:none] border-0 flex w-full flex-row justify-between px-[3.333vw] py-4"
                  onClick={() => toggleMenu(`${categoryTitleLower}`)}
                >
                  <div className="text-[.78rem] leading-[.9rem] text-gray-light1 font-normal">
                    {categoryTitle}
                  </div>
                  <div className="w-[.9rem] h-[.9rem] leading-[.9rem] -mt-0.5 flex justify-center items-center [&_img]:h-full">
                    {!menuVisible[`${categoryTitleLower}`] ? (
                      <IconArrowRightSimple />
                    ) : (
                      <ArrowDownSimples width={16} height={9} />
                    )}
                  </div>
                </button>
                <div
                  className={classNames(
                    'menu-list transition-all duration-700 w-full',
                    {
                      'flex flex-col opacity-100':
                        menuVisible[categoryTitleLower],
                      'hidden flex-col opacity-0': !menuVisible[
                        categoryTitleLower
                      ],
                    }
                  )}
                >
                  <ul>
                    {subcategory &&
                      subcategory.map(({ subcategoryTitle, link }) => (
                        <li key={`${subcategoryTitle}-li4`}>
                          <Link
                            to={normalizeInternalLink(link)}
                            key={`${subcategoryTitle}`}
                          >
                            {subcategoryTitle}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            );
          })
        )}
      <div className="w-full cursor-pointer border-b border-solid border-gray-light5">
        <div className="opacity-100 transition-all duration-700">
          <div className="[background:none] border-0 w-full flex flex-row justify-around">
            {!isLoggedIn ? (
              <>
                <button
                  type="button"
                  onClick={evt => {
                    evt.preventDefault();
                    showSignInModal();
                  }}
                >
                  Login
                </button>
                <Link
                  className="border-l-[1px] border-solid border-l-gray-light5 w-1/2 text-center px-0 py-4 text-[.78rem] font-normal leading-[.9rem] text-gray-light1 cursor-pointer"
                  to="/contact-us/"
                >
                  Contact Us
                </Link>
              </>
            ) : (
              <>
                <div className="flex flex-col items-center border-l-0 w-full">
                  <div className="pt-4 pb-2 text-[.78rem] font-normal leading-[.9rem]">
                    <span className="break-words">Hi, {firstName}</span>
                  </div>
                  <div className="pt-0 px-6 pb-4 border-l-0 w-full">
                    <CtaLink to="/customer/dashboard/" hasArrow={false}>
                      <IconUserAccount className="w-4" />
                      <div className="no">
                        <span className="[&_button]:p-0 [&_button]:ml-[5px]">
                          <CtaButton
                            style={{ background: { padding: 0 } }}
                            styling="link"
                            color="brand"
                            size="sm"
                            hasArrow={false}
                            block
                          >
                            Go to Account
                          </CtaButton>
                        </span>
                      </div>
                    </CtaLink>
                  </div>
                </div>
                <div className="border-l-[1px] border-solid border-l-gray-light5 w-1/2 text-center px-0 py-4 text-[.78rem] font-normal leading-[.9rem] text-gray-light1 cursor-pointer">
                  {/* temporary fix for centering the panels; need to change the whole style nested from parents later */}
                  <a href="/contact-us/">Contact Us</a>
                  <div />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <LoginSidebar show={signInModalVisible} onClose={hideSignInModal} />
    </div>
  );
};

export default ConsumerMobileFooterMenu;
